import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../components/styles.css";
import { ThemeProvider } from "@mui/material/styles";
import { Button } from "@mui/material";
import { themes } from "../../styles/themeStyles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Alert from "@mui/material/Alert";
import styles from "./styles.module.css";
import { createIdentification } from "../../services/api";
import { useCookies } from "react-cookie";
import FormField from "../../components/commons/FormField";
import Logo from "../../components/commons/Logo";
import CircularProgress from "@mui/material/CircularProgress";
import mixpanel from "mixpanel-browser";
import PasswordField from "../../components/commons/PasswordField";

const UserWelcomePage = () => {
  const { id: hash } = useParams();
  const [cookies, setCookie] = useCookies([
    "customerID",
    "name",
    "email",
    "AccessToken",
    "IdToken",
    "RefreshToken",
    "userID",
  ]);
  const [userName, setUserName] = useState(cookies.name ? cookies.name : "");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Send data to server and save the userId from response
  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userID = await createIdentification(
        hash,
        userName,
        setErrorMessage
      );
      if (userID !== undefined) {
        setCookie("userID", userID, { path: "/" });
        setCookie("name", userName, { path: "/" });
        mixpanel.identify(userID);
        mixpanel.people.set({
          $name: userName,
          $userid: userID,
          $hash: hash,
        });
        mixpanel.track("Name saved successfully");
        navigate(`/selection/${hash}`);
      }
    } catch (error) {
      mixpanel.track("Name not saved");
    }
    setLoading(false);
  };

  return (
    <div className={styles.ScreenContainer}>
      <Logo />
      <div className={styles.titleContainer}>
        <p className={styles.title}>
          La forma más fácil de hacer las cuentas con tus amigos.
        </p>
      </div>
      <div className={styles.errorMessage}>
        {errorMessage && <Alert severity="warning">{errorMessage}</Alert>}
      </div>
      <div className={styles.formContainer}>
        <FormField
          label="Escribe tu Nombre"
          type="text"
          value={userName ? userName : ""}
          setValue={setUserName}
        />
        <div style={{ margin: "2rem" }}></div>
      </div>
      {loading ? (
        <div className={styles.LoadingContainer}>
          <CircularProgress size={40} color="inherit" />
        </div>
      ) : (
        <div className={styles.buttonContainer}>
          <ThemeProvider theme={themes.ButtonTheme}>
            <Button
              disabled={userName === ""}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              // onClick={()=>setLoading(!loading)}
              endIcon={<ArrowForwardIosIcon />}
              style={{
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                letterSpacing: "0.1rem",
              }}
            >
              Ir al ticket
            </Button>
          </ThemeProvider>
        </div>
      )}

      <div style={{ margin: "0.5rem" }}></div>
      <p className={styles.disclaimer}>
        Al hacer click en Ir al ticket, aceptas nuestros{" "}
        <a
          href="/terminos-y-condiciones"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.disclaimerLink}
          onClick={() => mixpanel.track("Términos y Condiciones clicked")}
        >
          términos y condiciones
        </a>{" "}
        y la{" "}
        <a
          href="/politica-privacidad"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.disclaimerLink}
          onClick={() => mixpanel.track("Politica de Privacidad clicked")}
        >
          política de privacidad
        </a>
        .
      </p>
    </div>
  );
};

export default UserWelcomePage;
