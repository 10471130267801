import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  ThemeProvider,
} from "@mui/material";
import styles from "./styles.module.css";
import { themes } from "../../../styles/themeStyles";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface Props {
  label: string;
  value: string;
  setValue: (value: React.SetStateAction<string>) => void;
}

const PasswordField = (props: Props) => {
  const { label, value, setValue } = props;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={themes.LoginFieldsTheme}>
      <FormControl required sx={{ width: "100%" }} variant="outlined">
        <InputLabel
          sx={{ color: "#fff" }}
          htmlFor="outlined-adornment-password"
        >
          {label}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          value={value ? value : ""}
          onChange={(event) => setValue(event.target.value)}
          sx={{ color: "#fff" }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                sx={{ color: "#fff", opacity: "0.7" }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
      </FormControl>
      {/* <TextField
        required
        label={label}
        variant="outlined"
        type={showPassword ? "text" : "password"}
        value={value ? value : ""}
        onChange={(event) => setValue(event.target.value)}
        sx={{ width: "100%" }}
        InputLabelProps={{
          sx: { color: "white" },
        }}
        InputProps={{
          sx: {
            color: "white",
            padding: "0.5rem",
          },
          classes: {
            notchedOutline: styles.notchedOutline,
          },
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      /> */}
      <div className={styles.marginM}></div>
    </ThemeProvider>
  );
};

export default PasswordField;
