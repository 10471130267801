import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { themes } from "../../styles/themeStyles";
import styles from "./styles.module.css";
import { Alert, CircularProgress } from "@mui/material";
import { useCookies } from "react-cookie";
import { GetCustomerID, GetTokens } from "../../services/api";
import FormField from "../../components/commons/FormField";
import Logo from "../../components/commons/Logo";
import mixpanel from "mixpanel-browser";
import PasswordField from "../../components/commons/PasswordField";

const LoginPage = () => {
  const [cookies, setCookie] = useCookies([
    "customerID",
    "email",
    "name",
    "AccessToken",
    "IdToken",
    "RefreshToken",
  ]);

  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [customerEmail, setCustomerEmail] = useState<string>(cookies.email ? cookies.email : "");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // login con cognito
  async function handleLogin2(event: any) {
    event.preventDefault();
    setLoading(true);

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailRegex.test(customerEmail)) {
      try {
        const response = await GetTokens(customerEmail, password);

        if (typeof response !== "string") {
          const { AccessToken, IdToken, RefreshToken } = response;
          setCookie("AccessToken", AccessToken);
          setCookie("IdToken", IdToken);
          setCookie("RefreshToken", RefreshToken);

          try {
            const customerID = await GetCustomerID(customerEmail, IdToken);
            if (customerID) {
              setCookie("customerID", customerID);
              setAuth({ customerID: customerID });

              mixpanel.identify(customerID);
              mixpanel.track("Login", {
                "Login state": "success",
              });
              navigate("/profile");
            }
          } catch (error) {
            setError("Ha ocurrido un error, inténtalo de nuevo.");
            mixpanel.track("Login", {
              "Login state": "error",
            });
            setPassword("");
          }
        } else {
          setError(response);
          setPassword("");
          setTimeout(() => {
            setError("");
          }, 10000);
        }
      } catch (error) {
        setError("Ha ocurrido un error, inténtalo de nuevo.");
        setPassword("");
        setTimeout(() => {
          setError("");
        }, 10000);
      }
    }
    setLoading(false);
  }

  return (
    <div className={styles.ScreenContainer}>
      <Logo />
      <div className={styles.titleContainer}>
        <p className={styles.title}>Inicia sesión</p>
        <p className={styles.selectionSubtitle}>
          Ingresa tus credenciales para poder acceder a tu cuenta.
        </p>
      </div>
      {error && (
        <div className={styles.alertContainer}>
          <Alert severity="error">{error}</Alert>
        </div>
      )}
      <div className={styles.loginFieldsContainer}>
        <FormField
          label="Email"
          type="email"
          value={customerEmail}
          setValue={setCustomerEmail}
        />
        <PasswordField
          label="Contraseña"
          value={password ? password : ""}
          setValue={setPassword}
        />
        <div className={styles.LogInContainer}>
          <p className={styles.InfoText}>
            <a className={styles.forgotPassLink} href="/forgot-password">
              ¿Has olvidado tu contraseña?
            </a>
          </p>
        </div>
        <div className={styles.dividerL} />
      </div>
      {loading ? (
        <div className={styles.LoadingContainer}>
          <CircularProgress size={30} sx={{ color: "#1de9b6" }} />
        </div>
      ) : (
        <ThemeProvider theme={themes.ButtonTheme}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIosIcon />}
            style={{
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              letterSpacing: "0.1rem",
            }}
            onClick={handleLogin2}
          >
            Iniciar sesión
          </Button>
        </ThemeProvider>
      )}
      <div className={styles.dividerL} />
      <div className={styles.LogInContainer}>
        <p className={styles.InfoText}>
          Aún no tienes una cuenta?{" "}
          <a
            className={styles.logInLink}
            href="/join"
            onClick={() => {
              mixpanel.track("Login to Signup");
            }}
          >
            Regístrate
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;

/// antigua login
// const handleLogin = async () => {
//   const emailRegex =
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   if (emailRegex.test(customerEmail)) {
//     const objectApi = {
//       user_name: `${customerEmail}`,
//       password: `${password}`,
//     };

//     try {
//       const response = await axios.post(
//         "https://staging.api.bill.ydteam.es/authorize",
//         objectApi
//       );

//       // si la respuesta es 200 OK
//       if (response.status === 200) {
//         // guarda el token en el estado de tu aplicación
//         const { customer_id: customerID } = response.data.data;
//         console.log("customerID", customerID);
//         setCookie("customerID", customerID, { path: "/" });
//         setAuth({ customerID });
//         localStorage.setItem("customerID", customerID);
//         setCustomerEmail("");
//         setPassword("");

//         navigate("/profile");
//       }
//     } catch (e: any) {
//       if (!e?.response) {
//         setError("El servicio esta inactivo, inténtelo de nuevo más tarde");
//         setTimeout(() => {
//           setError("");
//         }, 5000);
//       } else if (e.response?.status === 400) {
//         setError("Revisa los campos que has introducido.");
//         setTimeout(() => {
//           setError("");
//         }, 5000);
//       } else if (e.response?.status === 401) {
//         setError("Acceso no autorizado.");
//         setTimeout(() => {
//           setError("");
//         }, 5000);
//       } else {
//         setError("Error en el inicio de sesión.");
//         setTimeout(() => {
//           setError("");
//         }, 5000);
//       }
//     }
//   } else {
//     setError(
//       "Revise los campos introducidos o inténtelo de nuevo más tarde."
//     );
//     setTimeout(() => {
//       setError("");
//     }, 5000);
//   }
// };
